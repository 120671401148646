import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { LayoutProvider, useLayoutContext } from '../contexts/layoutContext';
import useIsClient from '../hooks/use-is-client';
import Layout from '../components/layout/layout';
import playfulTheme from '../themes/playfulTheme';
import minimalTheme from '../themes/minimalTheme';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Logo from '../minimalComponents/hero/logo';

import { Link, graphql } from 'gatsby';

import {
  blogHeroStyles,
  minimal404Styles,
  copyStyles,
  backgroundDotStyles,
} from '../components/notFoundPage.styles';

export const NOT_FOUND_QUERY = graphql`
  query notFoundQuery {
    contentfulNotFound404Page {
      notFoundPage
      notFoundPageMinimalVersion
    }
  }
`;

const PlayfulNotFound = ({ data }) => {
  return (
    <>
      <Container maxWidth={false} css={blogHeroStyles} id="section0">
        <Container
          maxWidth="lg"
          className="side-padding"
          style={{ position: 'relative', height: '100vh' }}
        >
          <div className="img-container">
            <img src={`/images/logo-chat.png`} alt="The Supply" />
          </div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            css={copyStyles}
          >
            <Grid item xs={12} className="rich-text-section">
              <Typography variant="h4">
                {data?.contentfulNotFound404Page?.notFoundPage &&
                  data.contentfulNotFound404Page.notFoundPage}
              </Typography>
              <Link to="/" className="home-btn">
                Home
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <div css={backgroundDotStyles}></div>
    </>
  );
};

const MinimalNotFound = ({ data }) => {
  const theme = useTheme();
  const showMdAndUp = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm)
  );

  return (
    <Container
      maxWidth={false}
      css={minimal404Styles}
      disableGutters
      id="section0"
    >
      <Grid
        container
        direction={showMdAndUp ? 'row' : 'column-reverse'}
        justifyContent="space-between"
        alignItems="stretch"
        spacing={0}
        className="grid"
      >
        <Grid item xs={0} sm={4}>
          <div className="left-col">
            <div className="top">{showMdAndUp && <Logo active={true} />}</div>

            <div className="bottom">
              <div className="bottom-border"></div>
            </div>
          </div>
        </Grid>
        <Grid item container xs={12} sm={8} direction="column">
          <div className="right-col">
            <div className="top">
              <div style={{ position: 'relative' }}>
                {!showMdAndUp && <Logo active={true} />}
              </div>
              <div className="title-copy">
                <Typography variant="h4">
                  {data?.contentfulNotFound404Page
                    ?.notFoundPageMinimalVersion ? (
                    <>
                      {
                        data.contentfulNotFound404Page.notFoundPageMinimalVersion.split(
                          'here'
                        )[0]
                      }
                      <Link to="/" className="home-btn">
                        here
                      </Link>
                      {
                        data.contentfulNotFound404Page.notFoundPageMinimalVersion.split(
                          'here'
                        )[1]
                      }
                    </>
                  ) : null}
                </Typography>
              </div>
            </div>
            <div className="faux-buttons">
              <div className="button learn-more"></div>
              <div className="button talk"></div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

const NotFoundContent = ({ data }) => {
  const { isMinimal, firstLoadIn } = useLayoutContext();
  const { isClient, key } = useIsClient();

  useEffect(() => {
    console.log('isMinimal', isMinimal);
  }, [isMinimal]);

  return (
    <Layout
      key={key}
      isMinimal={isMinimal}
      theme={isMinimal ? minimalTheme : playfulTheme}
    >
      <div
        style={{
          opacity: firstLoadIn ? 0 : 1,
        }}
      >
        {/* <TopBar /> */}

        {isMinimal ? (
          <MinimalNotFound data={data} />
        ) : (
          <PlayfulNotFound data={data} />
        )}
      </div>
    </Layout>
  );
};

const NotFound = ({ data }) => {
  return (
    <LayoutProvider>
      <NotFoundContent data={data} />
    </LayoutProvider>
  );
};

export default NotFound;
