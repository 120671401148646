import { css } from '@emotion/react';

const styles = (theme) => css`
  background-color: ${theme.palette.supply.cream.main};
  padding: 0 0 8rem;
  overflow: hidden;
  ${theme.breakpoints.up('md')} {
    padding-bottom: 25rem;
  }

  h3 {
    font-family: 'Bogue-Regular';
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  ${'' /* 
  .top-bottom-spacing {
    margin: 8rem 0;
  }

  .limit-width {
    max-width: 720px;
  }

  .big-photo-section {
    .gatsby-image-wrapper {
      margin-bottom: ${theme.spacing(3)};
    }
    p {
      max-width: 480px;
    }
  }

  .two-up {
    .gatsby-image-wrapper {
      height: 400px;
    }
  }

  .img-lr {
    width: 100%;
    max-height: 500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  } */}
`;

export const blogHeroStyles = (theme) => css`
  background-color: ${theme.palette.supply.cream.main};
  ${'' /* padding: 0 0 8rem; */}
  ${'' /* overflow: hidden; */}
  ${'' /* ${theme.breakpoints.up('md')} {
    padding-bottom: 25rem;
  } */}

  ${theme.breakpoints.between(
    theme.breakpoints.values.sm,
    theme.breakpoints.values.md
  )} {
    height: 100vh;
  }

  .subhead {
    font-size: 2rem;
    max-width: 720px;
    margin-top: 5.5rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
  }

  .img-container {
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 3rem;
    img {
      max-width: 180px;
    }
  }

  .home-btn {
    background: ${theme.palette.text.primary};
    border: ${theme.palette.text.primary};
    width: 173px;
    font-size: 1.5rem;
    color: ${theme.palette.supply.cement.main};
    text-decoration: none;
    border-radius: 32px;
    display: inline-block;
    z-index: 1;
    padding: 1.5rem 0;
    margin-top: 7rem;
  }

  .rich-text-section {
    position: relative;
    z-index: 2;
    text-align: center;
    h4 {
      line-height: 1.25 !important;
    }
  }
`;

export const backgroundDotStyles = (theme) => css`
  background-image: radial-gradient(
    #e8e8e8 10%,
    ${theme.palette.supply.cream.main} 10%
  );
  background-position: 0 0;
  background-size: 60px 60px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const copyStyles = (theme) => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  left: 0;
  max-width: 850px;
  margin-left: 50%;
  transform: translate(-50%, -50%);
`;

export const minimal404Styles = (theme) => css`
  display: flex;
  background-color: ${theme.palette.background.default};
  height: 100vh;

  .grid {
    padding: 0;
    flex-wrap: nowrap;
    height: 100%;
  }

  .left-col {
    flex-direction: column;
    display: none;

    ${theme.breakpoints.up('sm')} {
      display: flex;
      height: 100%;
      border-right: 1px solid ${theme.palette.text.primary};
    }

    .top,
    .bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .top {
      height: 100%;
      padding: ${theme.spacing(0, 2, 4)};

      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(3)};
      }
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(4)};
      }
      ${theme.breakpoints.up('lg')} {
        padding: ${theme.spacing(4, 12, 4, 4)};
      }
    }
    .bottom {
      ${theme.breakpoints.up('sm')} {
        height: 440px;
        flex-shrink: 0;
      }
      ${theme.breakpoints.up('md')} {
        height: 380px;
        flex-shrink: 0;
      }
    }
    .bottom-border {
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 100%;
      border-top: 1px solid ${theme.palette.text.primary};
    }
  }

  .right-col {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 0px;

    ${theme.breakpoints.up('sm')} {
      border-bottom: none;
      padding-bottom: 90px;
    }

    .top {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: ${theme.spacing(4, 0, 2, 2)};

      ${theme.breakpoints.up('sm')} {
        align-items: flex-end;
        flex-direction: row;
        padding: ${theme.spacing(3, 3, 6, 3)};
      }
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(4, 4, 8, 4)};
      }

      .title-copy {
        height: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 100px;
        width: 100%;
        ${theme.breakpoints.up('sm')} {
          padding-bottom: 0px;
          max-width: 540px;
        }

        h4 {
          line-height: 1;
        }

        .home-btn {
          text-decoration: none;
          color: ${theme.palette.supply.salmon.main};
        }
      }
    }

    .faux-buttons {
      position: fixed;
      bottom: 0;
      right: 0;
      display: flex;
      height: 50px;
      width: 100vw;

      ${theme.breakpoints.up('sm')} {
        height: 75px;
        width: ${(8 / 12) * 100}vw;
      }
      ${theme.breakpoints.up('md')} {
        height: 90px;
      }

      .button {
        display: flex;
        width: 50%;
        height: 100%;

        &.learn-more {
          background-color: ${theme.palette.supply.darkGray};
          ${theme.breakpoints.up('sm')} {
            width: 45%;
          }
        }

        &.talk {
          background-color: ${theme.palette.supply.salmon.main};

          ${theme.breakpoints.up('sm')} {
            width: 55%;
          }
        }
      }
    }
  }

  /*
  ** LOGO
  */

  .logo {
    width: ${150 * 0.75}px;
    height: ${70 * 0.75}px;
    background-image: url(/images/supply-footer-logo.svg);
    background-size: ${95 * 0.75}px ${48 * 0.75}px;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    border: 1px solid ${theme.palette.text.primary};
    border-radius: ${35 * 0.75}px;

    ${theme.breakpoints.up('sm')} {
      width: 150px;
      height: 70px;
      background-size: 95px 48px;
      border-radius: 35px;
    }
  }
`;

export default styles;
